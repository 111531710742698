import { useApi } from '~/api/index';
import { components } from '~~/api-types';

export type FileDto = components['schemas']['FileResponseDto'];

export const apiCreateContentFile = async (url: string): Promise<FileDto> => {
  const api = useApi();

  return await api(`/app/files`, {
    method: 'POST',
    body: { url },
  });
};
