<template>
  <div class="flex items-start flex-col">
    <FileInput
      v-model="socialFileUrl"
      class="h-60 mt-1"
      :class="noCaption ? 'w-60' : 'w-81'"
      :accepted-types="acceptedTypes"
      :image-size-limit="SOCIAL_POST_IMAGE_SIZE_LIMIT"
      :video-size-limit="SOCIAL_POST_VIDEO_SIZE_LIMIT"
      :rules="[isRequired]"
      :hide-edit="!props.isFileEditable"
      :hide-delete="!props.isFileEditable"
      @delete="$emit('delete')"
      @upload-complete="changeFile"
    >
      <template v-if="props.isFileEditable" #icons>
        <Btn
          v-if="!isFirst"
          :variant="BtnVariant.FILLED"
          :color="BtnColor.SURFACE"
          :shape="BtnShape.ROUNDED"
          :size="BtnSize.SM"
          icon
          @click="$emit('move-left')"
        >
          <Icon :icon="ArrowLeft" />
        </Btn>
        <Btn
          v-if="!isLast"
          :variant="BtnVariant.FILLED"
          :color="BtnColor.SURFACE"
          :shape="BtnShape.ROUNDED"
          :size="BtnSize.SM"
          icon
          @click="$emit('move-right')"
        >
          <icon :icon="ArrowRight" />
        </Btn>
      </template>
    </FileInput>
    <TextArea
      v-if="!disableCaption && !noCaption"
      v-model="socialFile.caption"
      :label="$t('social-post-files-modal.caption')"
      :placeholder="$t('social-post-files-modal.caption')"
      :rules="socialFile.caption ? [maxLengthLimit] : undefined"
      class="flex-grow w-full"
    />
    <p
      v-else-if="!noCaption"
      class="text-sm text-secondary self-center mb-4 mt-4"
    >
      {{ $t('social-post-files-modal.single-file-info') }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { ContentSocialFileDto } from '@/api/contents';
import { Btn, BtnColor, BtnShape, BtnSize, BtnVariant } from '~/components/btn';
import { Icon } from '~/components/icon';
import ArrowRight from '@/assets/icons/arrow-right.svg?component';
import ArrowLeft from '@/assets/icons/arrow-left.svg?component';
import { apiCreateContentFile } from '@/api/files';
import { notify } from '@kyvg/vue3-notification';
import { AcceptedFileTypes } from '~/constants/files';
import {
  SOCIAL_POST_IMAGE_SIZE_LIMIT,
  SOCIAL_POST_VIDEO_SIZE_LIMIT,
} from '~/pages/my-posts/my-posts.constants';
import { FileInput } from '~/components/file-input';
import { TextArea } from '~/components/inputs/text-area';
import { useI18n } from 'vue-i18n';
import { maxLength } from '~/utils/validators';
import { MAX_CAPTION_LENGTH } from '~/pages/my-posts/_components/post-modal-content/_components/media-manager/media-manager.constants';

type Props = {
  allowVideos?: boolean;
  disableCaption?: boolean;
  noCaption?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  isFileEditable?: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'delete'): void;
  (e: 'move-left'): void;
  (e: 'move-right'): void;
}>();

const { t } = useI18n();
const socialFile = defineModel<ContentSocialFileDto>({ required: true });

const maxLengthLimit = maxLength(MAX_CAPTION_LENGTH);

const acceptedTypes = computed(() => {
  if (props.allowVideos)
    return `${AcceptedFileTypes.IMAGE}, ${AcceptedFileTypes.VIDEO}`;

  return AcceptedFileTypes.IMAGE;
});

const socialFileUrl = ref(socialFile.value.file.url);

const changeFile = async (socialFileUrl: string) => {
  try {
    socialFile.value.file = await apiCreateContentFile(socialFileUrl);
  } catch {
    notify({
      group: 'error',
      title: t('media-manager.upload-error'),
      duration: 5000,
    });
  }
};
</script>
